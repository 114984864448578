// extracted by mini-css-extract-plugin
export var actions = "header-social-module--actions--8a1aa";
export var buttonMobile = "header-social-module--button-mobile--fc2c0";
export var calendar = "header-social-module--calendar--4f728";
export var container = "header-social-module--container--4ca77";
export var desktopNavigation = "header-social-module--desktop-navigation--d8053";
export var grid = "header-social-module--grid--a89a0";
export var header = "header-social-module--header--db58b";
export var headerButton = "header-social-module--header-button--fe8bd";
export var logoBox = "header-social-module--logo-box--c8d77";
export var logotype = "header-social-module--logotype--03f54";
export var mobileNavigation = "header-social-module--mobile-navigation--dfb2e";
export var socialsContainer = "header-social-module--socials-container--a8d56";