import React from 'react';

import {
    wrapper,
    column,
    submenu,
    item,
    linkBox,
    link,
    imageLink,
    searchbarIsOpen,
    parentThird,
    parentFourth,
    reversedDirection,
} from './columns-submenu.module.scss';

import { INavLink } from '../../models/nav-link.model';

import NavLink from '../atoms/nav-link';

interface IThreeColumnsSubmenuProps {
    className?: string;
    navLinks: INavLink[];
    isSearchBarOpen: boolean;
    parentIndex: number;
}

const ColumnsSubmenu: React.FC<IThreeColumnsSubmenuProps> = ({
    className = '',
    navLinks,
    isSearchBarOpen,
    parentIndex,
}) => {
    const linksByColumn = [
        navLinks.slice(0, Math.ceil(navLinks.length / 3)),
        navLinks.slice(Math.ceil(navLinks.length / 3), Math.ceil(navLinks.length / 3) * 2),
        navLinks.slice(Math.ceil(navLinks.length / 3) * 2, navLinks.length),
    ];

    return (
        <div
            className={`${wrapper} ${className} ${
                isSearchBarOpen ? searchbarIsOpen : ''
            } ${getClassByParentIndex(parentIndex)} secondary`}
        >
            <div className={submenu}>
                {linksByColumn.map((columnLinks, index) => (
                    <ul className={column} key={index}>
                        {columnLinks.map((navLink) => {
                            return (
                                <li
                                    className={item}
                                    key={`nav-link-item-${navLink.linkId}-desktop`}
                                >
                                    <div className={linkBox}>
                                        <NavLink
                                            className={`${link} ${
                                                navLink.media?.length ? imageLink : ''
                                            }`}
                                            navLink={navLink}
                                            context={'header'}
                                        />
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                ))}
            </div>
        </div>
    );
};

function getClassByParentIndex(index: number) {
    if (index === 0 || index === 1) return '';
    if (index === 2) return parentThird;
    if (index === 3) return parentFourth;
    return reversedDirection;
}

export default ColumnsSubmenu;
