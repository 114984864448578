// extracted by mini-css-extract-plugin
export var column = "columns-submenu-module--column--7f522";
export var imageLink = "columns-submenu-module--image-link--b9501";
export var link = "columns-submenu-module--link--7b841";
export var linkBox = "columns-submenu-module--link-box--a873a";
export var parentFourth = "columns-submenu-module--parent-fourth--54c52";
export var parentThird = "columns-submenu-module--parent-third--b2330";
export var reversedDirection = "columns-submenu-module--reversed-direction--7a5a1";
export var searchbarIsOpen = "columns-submenu-module--searchbar-is-open--85760";
export var submenu = "columns-submenu-module--submenu--730ae";
export var wrapper = "columns-submenu-module--wrapper--4d745";