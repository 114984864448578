import React from "react";

interface IThemeStyle {
    style: string;
}

const ThemeStyle: React.FC<IThemeStyle> = ({style}) => {
    return (
        <style>
            {style}
        </style>
    )
}

export default ThemeStyle;