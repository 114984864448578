// extracted by mini-css-extract-plugin
export var active = "mobile-navigation-list-module--active--4f414";
export var button = "mobile-navigation-list-module--button--e3c99";
export var buttonReturn = "mobile-navigation-list-module--button-return--9e8e4";
export var calendar = "mobile-navigation-list-module--calendar--1870b";
export var iconReturn = "mobile-navigation-list-module--icon-return--5a1ee";
export var imageLink = "mobile-navigation-list-module--image-link--c1648";
export var link = "mobile-navigation-list-module--link--0cbf1";
export var linkBox = "mobile-navigation-list-module--link-box--99215";
export var list = "mobile-navigation-list-module--list--3e547";
export var socialsContainer = "mobile-navigation-list-module--socials-container--f4ee4";
export var sublist = "mobile-navigation-list-module--sublist--f2b8e";