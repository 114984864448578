import { ISetting } from '../models/setting.model';

export default function getCustomStyleFromSetting(settings: ISetting[]) {
    const customStyle = settings.find((element) => element.name === 'customCss');

    const variables = settings
        .map((element) => {
            if (element.name !== 'customCss') {
                return `${element.name}: ${element.value};`;
            }
            return null;
        })
        .join('');

    return `${customStyle?.value}:root{${variables}}`;
}
