import React from 'react';

import {
    secondary,
    tertiary,
    item,
    linkBox,
    link,
    imageLink,
    arrow,
} from './list-submenu.module.scss';
import { INavLink } from '../../models/nav-link.model';
import NavLink from '../atoms/nav-link';
import ArrowIcon from '../../assets/images/svg/arrow-right.svg';

interface IOneColumnSubmenuProps {
    className: string;
    navLinks: INavLink[];
    level?: number;
}

const ListSubmenu: React.FC<IOneColumnSubmenuProps> = ({ className, navLinks, level = 1 }) => {
    return (
        <ul
            className={`${className} ${getListClass(level)} ${
                level === 2 ? 'tertiary' : 'secondary'
            }`}
        >
            {navLinks.map((navLink) => {
                return (
                    <li className={item} key={`nav-link-item-${navLink.linkId}-desktop`}>
                        <div className={linkBox}>
                            <NavLink
                                className={`${link} ${navLink.media?.length ? imageLink : ''}`}
                                navLink={navLink}
                                context={'header'}
                            />
                            {navLink.links && navLink.links.length > 0 && (
                                <ArrowIcon className={arrow} />
                            )}
                        </div>
                        {navLink.links && navLink.links.length > 0 && level < 2 && (
                            <ListSubmenu
                                className={className}
                                navLinks={navLink.links}
                                level={level + 1}
                            />
                        )}
                    </li>
                );
            })}
        </ul>
    );
};

function getListClass(level: number) {
    if (level === 1) return secondary;
    return tertiary;
}

export default ListSubmenu;
