import React, { useState } from 'react';
import { Link } from 'gatsby';

import {
    list,
    sublist,
    link,
    linkBox,
    imageLink,
    active,
    button,
    buttonReturn,
    iconReturn,
    socialsContainer,
    calendar,
} from './mobile-navigation-list.module.scss';

import ArrowIcon from '../../assets/images/svg/arrow-right.svg';
import CalendarIcon from '../../assets/images/svg/calendar.svg';

import { INavLink } from '../../models/nav-link.model';
import { ISocialLink } from '../../models/social-link.model';
import useTranslations from '../../hooks/use-translations';
import { useUser } from '../../hooks/use-user';
import { usePagePathname } from '../../hooks/use-page-pathname';

import NavLink from '../atoms/nav-link';
import SocialLink from '../atoms/social-link';

interface IMobileNavigationList {
    className: string;
    navLinks: INavLink[];
    isActive?: boolean;
    parentLabel?: string;
    onReturn?(): void;
    socials?: ISocialLink[];
}

const MobileNavigationList: React.FC<IMobileNavigationList> = ({
    className,
    navLinks,
    isActive,
    parentLabel,
    onReturn,
    socials,
}) => {
    const t = useTranslations('MobileNavigationList');

    const [activeLink, setActiveLink] = useState<INavLink | null>(null);

    const userState = useUser();
    const pathNames = usePagePathname();

    const handleShowSublist = (link: INavLink) => {
        return () => {
            setActiveLink(link);
        };
    };

    const handleReturn = () => {
        if (typeof onReturn !== 'function') return;
        onReturn();
    };

    const handleSublistReturn = () => {
        setActiveLink(null);
    };

    return (
        <>
            <ul className={`${className} ${isActive ? active : ''} ${list}`}>
                {isActive && parentLabel && (
                    <button className={buttonReturn} onClick={handleReturn}>
                        <ArrowIcon className={iconReturn} />
                        <span>{parentLabel}</span>
                    </button>
                )}
                {navLinks.map((navLink) => {
                    return (
                        <li key={`nav-link-item-${navLink.linkId}-mobile`}>
                            <div className={linkBox}>
                                <NavLink
                                    className={`${link} ${navLink.media?.length ? imageLink : ''}`}
                                    navLink={navLink}
                                    context={'header'}
                                />
                                {navLink.links && navLink.links.length > 0 && (
                                    <button
                                        className={`${button}`}
                                        onClick={handleShowSublist(navLink)}
                                        title={`${t.goTo} ${navLink.label}`}
                                    >
                                        <ArrowIcon />
                                    </button>
                                )}
                            </div>
                            {navLink.links && navLink.links.length > 0 && (
                                <MobileNavigationList
                                    className={sublist}
                                    navLinks={navLink.links}
                                    isActive={activeLink?.linkId === navLink.linkId}
                                    parentLabel={navLink.label}
                                    onReturn={handleSublistReturn}
                                />
                            )}
                        </li>
                    );
                })}
            </ul>
            {socials && (
                <div className={socialsContainer}>
                    {userState.isLoggedIn && (
                        <Link className={calendar} to={pathNames.dashboard || '/'}>
                            <CalendarIcon />
                        </Link>
                    )}
                    {socials.map((link, i) => {
                        return <SocialLink key={`social-link-${i}`} socialLink={link} />;
                    })}
                </div>
            )}
        </>
    );
};

export default MobileNavigationList;
