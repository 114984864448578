import React, { useState } from 'react';

import {
    header,
    grid,
    container,
    logoBox,
    logotype,
    desktopNavigation,
    mobileNavigation,
    actions,
    scrolled,
    buttonMobile,
    headerButton,
    socialsContainer,
    calendar,
} from './header-social.module.scss';

import MenuIcon from '../../assets/images/svg/menu.svg';
import CloseIcon from '../../assets/images/svg/close.svg';
import CalendarIcon from '../../assets/images/svg/calendar.svg';

import { ILogo } from '../../models/logo.model';
import { ISection } from '../../models/section.model';
import { INavLink } from '../../models/nav-link.model';
import { ISocialLink } from '../../models/social-link.model';
import { TSubmenuVariants } from '../../models/components-variants';
import { dataLayerEvents } from '../../config/data-layer';
import useTranslations from '../../hooks/use-translations';

import Logo from '../atoms/logo';
import IconButton from '../atoms/icon-button';
import Section from '../hoc/section';
import DesktopNavigation from '../organisms/desktop-navigation';
import MobileNavigation from '../organisms/mobile-navigation';
import SocialLink from '../atoms/social-link';
import { useUser } from '../../hooks/use-user';
import { Link } from 'gatsby';
import { usePagePathname } from '../../hooks/use-page-pathname';

export interface ISectionHeader extends ISection {
    items: {
        logo: ILogo | null;
        links: INavLink[];
        userLinks: INavLink[];
        socials: ISocialLink[];
    };
    settings: {
        submenuLayout: TSubmenuVariants;
    };
}

interface IHeaderProps {
    className?: string;
    section: ISectionHeader;
}

const HeaderSocial: React.FC<IHeaderProps> = ({ className = '', section }) => {
    const t = useTranslations('Header');
    const {
        sectionId,
        css,
        style,
        items: { links, logo, socials },
        settings: { submenuLayout },
    } = section;
    const [isScrolled] = useState<boolean>(true);
    const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
    const [isSearchBarOpen, setIsSearchBarOpen] = useState(false);

    const userState = useUser();
    const pathNames = usePagePathname();

    const handleShowNavigation = () => {
        setIsMobileNavOpen(true);
        setIsSearchBarOpen(false);
        dataLayerEvents.menuClick('burger menu', `${t.buttons.showNavigation}`);
    };

    const handleCloseNavigation = () => {
        setIsMobileNavOpen(false);
    };

    return (
        <>
            <Section
                sectionId={sectionId}
                Tag="header"
                className={`${header} ${className} ${isScrolled ? scrolled : ''}`}
                classes={{ container: container }}
                css={css}
                style={style}
            >
                <div className={grid}>
                    <div className={logoBox}>
                        {logo && (
                            <Logo className={logotype} logo={logo} isHeightResponsive={true} />
                        )}
                    </div>
                    <DesktopNavigation
                        className={desktopNavigation}
                        navLinks={links}
                        submenuLayout={submenuLayout}
                        isSearchBarOpen={isSearchBarOpen}
                    />
                    <MobileNavigation
                        className={mobileNavigation}
                        navLinks={links}
                        isMobileOpen={isMobileNavOpen}
                        socials={socials}
                    />
                    <div className={socialsContainer}>
                        {userState.isLoggedIn && (
                            <Link className={calendar} to={pathNames.dashboard || '/'}>
                                <CalendarIcon />
                            </Link>
                        )}

                        {socials.map((link, i) => {
                            return <SocialLink socialLink={link} key={`social-${i}`} />;
                        })}
                    </div>
                    <div className={actions}>
                        {!isMobileNavOpen && (
                            <IconButton
                                className={`${headerButton} ${buttonMobile}`}
                                title={t.buttons.showNavigation}
                                onClick={handleShowNavigation}
                            >
                                <MenuIcon />
                            </IconButton>
                        )}
                        {isMobileNavOpen && (
                            <IconButton
                                className={`${headerButton} ${buttonMobile}`}
                                title={t.buttons.hideNavigation}
                                onClick={handleCloseNavigation}
                            >
                                <CloseIcon />
                            </IconButton>
                        )}
                    </div>
                </div>
            </Section>
        </>
    );
};

export default HeaderSocial;
