import React from 'react';

import {
    navigation,
    item,
    link,
    imageLink,
    linkBox,
    submenu,
} from './desktop-navigation.module.scss';

import { INavLink } from '../../models/nav-link.model';
import { TSubmenuVariants } from '../../models/components-variants';

import NavLink from '../atoms/nav-link';
import ColumnsSubmenu from '../molecules/columns-submenu';
import ListSubmenu from '../molecules/list-submenu';

interface IDesktopNavigationProps {
    className?: string;
    navLinks: INavLink[];
    submenuLayout: TSubmenuVariants;
    isSearchBarOpen: boolean;
}

const DesktopNavigation: React.FC<IDesktopNavigationProps> = ({
    className,
    navLinks,
    submenuLayout,
    isSearchBarOpen,
}) => {
    if (!navLinks.length) return null;

    return (
        <nav className={className}>
            <ul className={navigation}>
                {navLinks &&
                    navLinks.map((navLink, index) => {
                        return (
                            <li className={item} key={`nav-link-item-${navLink.linkId}-desktop`}>
                                <div className={linkBox}>
                                    <NavLink
                                        className={`${link} ${
                                            navLink.media?.length ? imageLink : ''
                                        }`}
                                        navLink={navLink}
                                        context={'header'}
                                    />
                                </div>
                                {submenuLayout === 'columns' &&
                                    navLink.links &&
                                    navLink.links.length > 0 && (
                                        <ColumnsSubmenu
                                            className={submenu}
                                            navLinks={navLink.links}
                                            isSearchBarOpen={isSearchBarOpen}
                                            parentIndex={index}
                                        />
                                    )}
                                {submenuLayout === 'list' &&
                                    navLink.links &&
                                    navLink.links.length > 0 && (
                                        <ListSubmenu className={submenu} navLinks={navLink.links} />
                                    )}
                            </li>
                        );
                    })}
            </ul>
        </nav>
    );
};

export default DesktopNavigation;
