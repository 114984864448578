import React from 'react';
import { Helmet } from 'react-helmet';

import { noScroll, container, mobileOpen, navList } from './mobile-navigation.module.scss';
import { breakpoints } from '../../config/breakpoints';
import { INavLink } from '../../models/nav-link.model';
import { ISocialLink } from '../../models/social-link.model';
import useMediaQuery from '../../hooks/use-media-query';

import MobileNavigationList from '../molecules/mobile-navigation-list';

interface IMobileNavigation {
    className: string;
    navLinks: INavLink[];
    isMobileOpen: boolean;
    socials?: ISocialLink[];
}

const MobileNavigation: React.FC<IMobileNavigation> = ({
    className,
    navLinks,
    isMobileOpen,
    socials,
}) => {
    const isIPad = useMediaQuery(breakpoints.iPad);

    if (!navLinks.length) return null;

    return (
        <>
            {isMobileOpen && <Helmet bodyAttributes={{ class: noScroll }} />}
            <nav
                className={`${container} ${className} ${isMobileOpen && isIPad ? mobileOpen : ''}`}
            >
                <MobileNavigationList className={navList} navLinks={navLinks} socials={socials} />
            </nav>
        </>
    );
};

export default MobileNavigation;
